var modalTrigger = document.querySelector('#modal-trigger');
var modal = document.querySelector('#modal');
var html = document.querySelector('html');
var body = document.querySelector('body');

modalTrigger.addEventListener('click', function(e){
	modal.classList.add('visible');
  html.classList.add('overlay');
	body.classList.add('overflow-hidden');
});

modal.querySelector('button').addEventListener('click', function(e){
	modal.classList.remove('visible');
  html.classList.remove('overlay');
	body.classList.remove('overflow-hidden');
});
